@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  input {
    @apply outline-none;
    @apply border-none;
  }
}
